<template>
    <nav class="navbar navbar-expand-lg custom-navbar">
        <div class="container-fluid align-items-center">
            <!-- Logo a la izquierda -->
            <div class="navbar-logo d-flex align-items-center">
                <div class="logo-container">
                    <img src="@/assets/images/logo.png" alt="Logo" class="logo" />
                </div>
                <span class="navbar-brand ms-2">GPX Planeta IP</span>
            </div>

            <!-- Menú de navegación central (se oculta en dispositivos pequeños) -->
            <div class="navbar-center mx-auto d-none d-lg-block">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/dashboard" active-class="active">Tablero</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/activities" active-class="active">Actividades</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/users" active-class="active">Usuarios</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/record" active-class="active">Historial</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/settings" active-class="active">Ajustes</router-link>
                    </li>
                    <!-- Opción de Cerrar Sesión en escritorio -->
                    <li class="nav-item">
                        <a class="nav-link logout-desktop btn-logout" href="#" @click="logout">Cerrar Sesión</a>
                    </li>
                </ul>
            </div>

            <!-- Iconos a la derecha -->
            <div class="navbar-icons d-flex align-items-center">
                <div class="user-info d-flex align-items-center">
                    <!-- Gravatar aleatorio -->
                    <img :src="gravatarUrl" alt="Gravatar" class="wallet-icon" />
                    <span class="user-name ms-2 d-none d-md-inline">Perfil</span>
                </div>

                <!-- Icono de menú en dispositivos móviles -->
                <div class="icon-menu ms-3 d-lg-none">
                    <button class="btn btn-circle" @click="toggleSidebar">
                        <i class="bi bi-list"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- Sidebar -->
        <div class="sidebar" :class="{ 'active': sidebarOpen }">
            <div class="sidebar-content">
                <button class="close-btn" @click="toggleSidebar">×</button>
                <ul class="sidebar-nav">
                    <li><router-link to="/dashboard">Tablero</router-link></li>
                    <li><router-link to="/activities">Actividades</router-link></li>
                    <li><router-link to="/users">Usuarios</router-link></li>
                    <li><router-link to="/record">Historial</router-link></li>
                    <li><router-link to="/settings">Ajustes</router-link></li>
                    <!-- Opción de Cerrar Sesión en el sidebar (móviles) -->
                    <li><a href="#" class="logout btn-logout" @click="logout">Cerrar Sesión</a></li>
                </ul>
            </div>
        </div>

        <!-- Fondo oscuro al abrir el sidebar -->
        <div class="overlay" :class="{ 'active': sidebarOpen }" @click="toggleSidebar"></div>
    </nav>
</template>

<script>
import CryptoJS from 'crypto-js';

export default {
    name: 'MainNavbar',
    data() {
        return {
            gravatarUrl: '',
            sidebarOpen: false, // Estado para manejar la apertura del sidebar
        };
    },
    mounted() {
        // Generar una URL de Gravatar aleatoria
        const email = 'random@example.com'; // Usa un correo electrónico aleatorio o fijo
        const hash = this.md5(email.trim().toLowerCase()); // Genera el hash MD5
        this.gravatarUrl = `https://www.gravatar.com/avatar/${hash}?d=identicon`;
    },
    methods: {
        md5(value) {
            // Usar crypto-js para generar el hash MD5
            return CryptoJS.MD5(value).toString();
        },
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen; // Alterna el estado del sidebar
        },
        logout() {
            // Eliminar token de autenticación (si lo estás usando)
            localStorage.removeItem('token');
            // Redirigir al login
            this.$router.push('/');
        }
    },
};
</script>

<style scoped>
/* Estilos del navbar */
.custom-navbar {
    background-color: #1c1c1e;
    padding: 10px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1030; /* Bootstrap's fixed-top z-index is 1030 */
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.logo-container {
    background-color: #fff;
    border-radius: 50%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 30px;
    height: 30px;
}

.navbar-brand {
    font-size: 1.2em;
    color: #fff;
    font-weight: bold;
}

.navbar-center {
    flex-grow: 1;
    justify-content: center;
}

.navbar-nav {
    list-style: none;
    padding-left: 0;
    display: flex;
    gap: 20px;
}

.nav-link {
    color: #fff;
    padding: 10px 15px;
    border-radius: 20px;
    text-decoration: none;
}

.nav-link.active {
    background-color: #fff;
    color: #000;
}

.nav-link:hover {
    background-color: #444;
    color: #fff;
}

.navbar-icons {
    display: flex;
    align-items: center;
    /* z-index ya está manejado por fixed-top */
}

.wallet-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.user-name {
    font-weight: bold;
    color: #fff;
}

.icon-menu .btn-circle {
    background-color: #333;
    border-radius: 50%;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-menu .btn-circle:hover {
    background-color: #444;
}

/* Sidebar */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    height: 100%;
    background-color: #1c1c1e;
    z-index: 1040; /* Asegúrate de que esté por encima del navbar */
    padding: 20px;
    transition: left 0.3s ease;
}

.sidebar.active {
    left: 0;
}

.sidebar-content {
    color: #fff;
}

.sidebar-nav {
    list-style-type: none;
    padding-left: 0;
}

.sidebar-nav li {
    margin-bottom: 15px;
}

.sidebar-nav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    transition: color 0.3s ease;
}

.sidebar-nav a:hover {
    color: #ccc;
}

/* Estilo para el botón de cerrar sesión (desktop y móvil) */
.btn-logout {
    background-color: #dc3545;
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
    text-align: center;
    display: inline-block;
}

.btn-logout:hover {
    background-color: #c82333;
    box-shadow: 0 4px 10px rgba(220, 53, 69, 0.5);
}

/* Botón de cierre del sidebar */
.close-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 2em;
    position: absolute;
    top: 10px;
    right: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.3s, opacity 0.3s ease;
    z-index: 1030; /* Debe estar por debajo del sidebar */
}

.overlay.active {
    opacity: 1;
    visibility: visible;
}

/* Responsivo */
@media (max-width: 991.98px) {
    .navbar-center {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .user-name {
        display: none;
    }
}
</style>
