<template>
    <!-- Navbar -->
    <Navbar />

    <div class="activities">

        <!-- Contenido principal -->
        <div class="main-content">
            <!-- Título -->
            <div class="section-header">
                <h2 class="section-title">Actividades Recientes</h2>
            </div>

            <!-- Contenedor de actividades -->
            <div class="activities-grid">
                <div class="grid-item card">
                    <div class="card-body">
                        <h5 class="card-title">Tareas Completadas</h5>
                        <p class="card-text">8 tareas completadas hoy</p>
                    </div>
                </div>
                <div class="grid-item card">
                    <div class="card-body">
                        <h5 class="card-title">Tareas Pendientes</h5>
                        <p class="card-text">5 tareas pendientes</p>
                    </div>
                </div>
                <div class="grid-item card">
                    <div class="card-body">
                        <h5 class="card-title">Última Actividad</h5>
                        <p class="card-text">Hace 1 hora</p>
                    </div>
                </div>
                <div class="grid-item card large-card">
                    <div class="card-body">
                        <h5 class="card-title">Resumen General</h5>
                        <p class="card-text">Visualiza un resumen de todas las actividades en tiempo real</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <Footer />
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';

export default {
    name: 'ActivitiesView',
    components: {
        Navbar,
        Footer
    },
};
</script>

<style scoped>
html,
body,
.activities {
    height: 100%;
    /* El contenedor de actividades ocupa el 100% de la pantalla */
    margin: 0;
    padding: 0;
}

.activities {
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    /* Esto asegura que el contenido principal ocupe el espacio disponible */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #f8f9fa;
    /* Fondo claro */
    overflow-y: auto;
    /* Permite el scroll si el contenido es mayor que la altura de la pantalla */
}

.section-header {
    text-align: left;
    padding: 20px;
    color: #343a40;
    /* Texto oscuro */
    background-color: #e9ecef;
    /* Fondo más claro */
    border-bottom: 1px solid #dee2e6;
    /* Borde claro */
}

.section-title {
    font-size: 2em;
    font-weight: 600;
    color: #000000;
    /* Título en negro */
}

.activities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    /* Las filas se ajustan dinámicamente */
    gap: 20px;
    margin-top: 20px;
}

.card {
    background-color: #ffffff;
    /* Fondo blanco */
    color: #212529;
    /* Texto oscuro */
    border: none;
    /* Sin bordes */
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.05);
    /* Sombras modernas */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Transiciones suaves */
    display: flex;
    align-items: center;
    justify-content: center;
}

.large-card {
    grid-column: span 3;
}

.card:hover {
    transform: translateY(-10px);
    /* Efecto de elevación al pasar el mouse */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.1);
    /* Sombras más profundas en hover */
}

.card-title {
    font-size: 1.3em;
    font-weight: bold;
    color: #000000;
    /* Color negro para el título */
}

.card-text {
    font-size: 1em;
    color: #495057;
    /* Color gris oscuro */
}

.card-body {
    padding: 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
}
</style>
