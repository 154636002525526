<template>
    <!-- Navbar -->
    <Navbar />
    
    <div class="dashboard">

        <!-- Contenido principal -->
        <div class="main-content">
            <!-- Título de bienvenida -->
            <div class="welcome-section">
                <h2 class="welcome-message">Bienvenid@ de nuevo, administrador</h2>
            </div>

            <!-- Contenedor del dashboard -->
            <div class="dashboard-grid">
                <!-- Mapa Leaflet -->
                <div :class="['grid-item', 'map-container', { fullscreen: isFullscreen }]">
                    <div id="map" class="map"></div>

                    <!-- Botón de pantalla completa (cuando no está en pantalla completa) -->
                    <button v-if="!isFullscreen" class="fullscreen-btn" @click="toggleFullscreen">
                        <i class="bi bi-arrows-fullscreen"></i>
                    </button>

                    <!-- Botón de cerrar en modo pantalla completa -->
                    <button v-if="isFullscreen" class="close-btn" @click="toggleFullscreen">
                        <i class="bi bi-x-lg"></i>
                    </button>
                </div>
            </div>
        </div>

    </div>

    <!-- Footer -->
    <Footer />
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

export default {
    name: 'DashboardView',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            isFullscreen: false, // Estado para controlar pantalla completa
            map: null, // Referencia al mapa
        };
    },
    mounted() {
        this.initializeMap()
            .then(() => {
                console.log("Mapa cargado correctamente");
            })
            .catch((error) => {
                console.error("Error al cargar el mapa:", error);
            });

        // Escucha el evento de teclado "Escape" para salir de pantalla completa
        document.addEventListener('keydown', this.handleEscapeKey);
    },
    beforeUnmount() {
        // Eliminar el event listener para la tecla "Escape"
        document.removeEventListener('keydown', this.handleEscapeKey);
    },
    methods: {
        async initializeMap() {
            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    try {
                        this.map = L.map('map').setView([4.60971, -74.08175], 12);
                        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                            attribution: '&copy; contributors de OpenStreetMap',
                        }).addTo(this.map);
                        resolve();
                    } catch (error) {
                        reject(error);
                    }
                });
            });
        },
        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
            // Asegúrate de que el mapa se actualice correctamente
            this.$nextTick(() => {
                this.map.invalidateSize();
            });
        },
        handleEscapeKey(event) {
            if (event.key === 'Escape' && this.isFullscreen) {
                this.toggleFullscreen();
            }
        }
    }
};
</script>

<style scoped>
html,
body,
.dashboard,
.main-content {
    height: 100%;
    /* Asegura que todos los niveles ocupen el 100% de la altura */
    margin: 0;
    padding: 0;
}

.dashboard {
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    /* Permite que el contenido ocupe todo el espacio disponible */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #f8f9fa;
    /* Fondo claro */
    overflow-y: auto;
    /* Permite el scroll si el contenido es mayor que la pantalla */
}

.welcome-section {
    text-align: left;
    padding: 20px;
    color: #343a40;
    /* Texto oscuro para contraste */
    background-color: #e9ecef;
    /* Fondo más claro */
    border-bottom: 1px solid #dee2e6;
    /* Borde claro */
}

.welcome-message {
    font-size: 2em;
    font-weight: 600;
    color: #000000;
    /* Color negro para el mensaje de bienvenida */
}

/* Grid principal para el mapa */
.dashboard-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    margin-top: 20px;
    flex-grow: 1;
    /* Permite que el grid crezca y llene el espacio */
}

/* Mapa ocupa todo el ancho disponible */
.map-container {
    width: 100%;
    height: 500px;
    /* Altura ajustable del mapa */
    border-radius: 20px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    /* Sombra ligera */
    position: relative;
    /* Asegura que el botón de cerrar esté posicionado correctamente */
    transition: all 0.4s ease-in-out;
    /* Transición suave */
}

.map-container.fullscreen {
    position: fixed;
    top: 70px;
    /* Deja espacio para el navbar fijo */
    left: 0;
    width: 100vw;
    /* Ancho completo de la ventana */
    height: calc(100vh - 70px);
    /* Altura completa de la ventana, menos el espacio del navbar */
    z-index: 999;
    /* Asegura que esté por encima de otros elementos */
    border-radius: 0;
    /* Sin bordes redondeados en pantalla completa */
    margin: 0;
    box-shadow: none;
    /* Quita las sombras en pantalla completa */
}

.map {
    height: 100%;
    width: 100%;
}

/* Botón de pantalla completa */
.fullscreen-btn,
.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s;
    z-index: 1000;
    /* Asegura que el botón esté por encima del mapa */
}

.fullscreen-btn:hover,
.close-btn:hover {
    background: rgba(255, 255, 255, 1);
}

.fullscreen-btn i,
.close-btn i {
    font-size: 1.2em;
    color: #343a40;
}
</style>
