<template>
  <div id="app" class="app-container">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Asegura que ocupe el 100% de la altura de la pantalla */
}

router-view {
  flex: 1; /* Permite que el contenido ocupe todo el espacio disponible */
  display: flex;
  flex-direction: column;
}

/* Personalización del scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Hacemos el scrollbar más delgado */
  z-index: 99999;
}

::-webkit-scrollbar-track {
  background: #e9ecef; /* Fondo del track */
  border-radius: 10px; /* Bordes redondeados para el track */
}

::-webkit-scrollbar-thumb {
  background-color: #1C1C1E; /* Color oscuro para la barra (scroll thumb) */
  border-radius: 10px; /* Bordes redondeados de la barra */
  border: 1px solid #ffffff; /* Añadimos un borde blanco más delgado */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #343a40; /* Color más claro al pasar el mouse por encima */
}

/* Scrollbar personalizado para Firefox */
body {
  scrollbar-width: thin; /* Define el grosor del scrollbar */
  scrollbar-color: #1C1C1E #e9ecef; /* Color de la barra y del fondo del track */
}
</style>
