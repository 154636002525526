<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <!-- Sección de Información -->
                <div class="col-md-4 mb-4">
                    <h5 class="footer-title">Sobre Nosotros</h5>
                    <p class="footer-text">
                        GPX Planeta IP es una empresa líder en soluciones de GPS y tecnología de punta.
                        Nos dedicamos a ofrecer servicios innovadores que facilitan la vida de nuestros clientes.
                    </p>
                </div>

                <!-- Sección de Enlaces -->
                <div class="col-md-4 mb-4">
                    <h5 class="footer-title">Enlaces Rápidos</h5>
                    <ul class="footer-links">
                        <li><router-link to="/dashboard">Tablero</router-link></li>
                        <li><router-link to="/activities">Actividades</router-link></li>
                        <li><router-link to="/users">Usuarios</router-link></li>
                        <li><router-link to="/record">Historial</router-link></li>
                        <li><router-link to="/settings">Ajustes</router-link></li>
                    </ul>
                </div>

                <!-- Sección de Contacto -->
                <div class="col-md-4 mb-4">
                    <h5 class="footer-title">Contacto</h5>
                    <ul class="footer-contact">
                        <li><i class="bi bi-geo-alt-fill"></i> Calle Falsa 123, Ciudad, País</li>
                        <li><i class="bi bi-envelope-fill"></i> contacto@planetaip.com</li>
                        <li><i class="bi bi-telephone-fill"></i> +1 234 567 890</li>
                    </ul>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col-12 text-center">
                    <p class="mb-0">© {{ currentYear }} GPX Planeta IP. Todos los derechos reservados.</p>
                    <div class="social-icons mt-2">
                        <a href="https://www.facebook.com/tuempresa" class="social-link" target="_blank"
                            rel="noopener noreferrer"><i class="bi bi-facebook"></i></a>
                        <a href="https://twitter.com/tuempresa" class="social-link" target="_blank"
                            rel="noopener noreferrer"><i class="bi bi-twitter"></i></a>
                        <a href="https://www.instagram.com/tuempresa" class="social-link" target="_blank"
                            rel="noopener noreferrer"><i class="bi bi-instagram"></i></a>
                        <a href="https://www.linkedin.com/company/tuempresa" class="social-link" target="_blank"
                            rel="noopener noreferrer"><i class="bi bi-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'AppFooter',
    data() {
        return {
            currentYear: new Date().getFullYear()
        };
    }
};
</script>

<style scoped>
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.container {
    flex: 1;
}

.footer {
    background-color: #1c1c1e;
    color: #fff;
    padding: 40px 0;
    width: 100%;
    position: relative;
}

.footer-title {
    font-size: 1.25rem;
    margin-bottom: 20px;
    color: #fff;
}

.footer-text {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #ddd;
}

.footer-links,
.footer-contact {
    list-style: none;
    padding: 0;
}

.footer-links li,
.footer-contact li {
    margin-bottom: 10px;
}

.footer-links li a {
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links li a:hover {
    color: #fff;
}

.footer-contact li i {
    margin-right: 10px;
    color: #fff;
}

.social-icons {
    margin-top: 15px;
}

.social-link {
    display: inline-block;
    margin: 0 10px;
    color: #ddd;
    font-size: 1.2rem;
    transition: color 0.3s;
}

.social-link:hover {
    color: #fff;
}

@media (max-width: 767.98px) {
    .footer {
        padding: 30px 0;
    }
}
</style>
