<template>
    <div class="login-page">
        <!-- Condición para cargar el video solo en pantallas grandes -->
        <video v-if="!isMobile" autoplay muted loop id="bg-video">
            <source src="@/assets/videos/back_login.mp4" type="video/mp4">
            Tu navegador no soporta el video.
        </video>
        <!-- Imagen de fondo para móviles -->
        <div v-else class="bg-image"></div>
        <div class="container-fluid d-flex align-items-center justify-content-center vh-100">
            <div class="card shadow-sm p-4" style="max-width: 400px; width: 100%;">
                <div class="text-center mb-4">
                    <img src="@/assets/images/logo.png" alt="Logo" class="img-fluid" style="max-width: 150px;" />
                </div>
                <form @submit.prevent="handleLogin">
                    <div class="mb-3">
                        <label for="email" class="form-label">Correo electrónico</label>
                        <input 
                            type="email" 
                            id="email" 
                            v-model="email" 
                            @input="clearError('email')" 
                            class="form-control" 
                            placeholder="Ingresa tu correo" 
                        />
                        <small v-if="formSubmitted && errors.email" class="text-danger">
                            {{ errors.email[0] }}
                        </small>
                    </div>

                    <div class="mb-3">
                        <label for="password" class="form-label">Contraseña</label>
                        <div class="input-group">
                            <input 
                                :type="showPassword ? 'text' : 'password'" 
                                id="password" 
                                v-model="password" 
                                @input="clearError('password')" 
                                class="form-control" 
                                placeholder="Ingresa tu contraseña" 
                            />
                            <button 
                                type="button" 
                                class="btn btn-outline-secondary input-group-text"
                                @click="togglePasswordVisibility"
                            >
                                <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'"></i>
                            </button>
                        </div>
                        <small v-if="formSubmitted && errors.password" class="text-danger">
                            {{ errors.password[0] }}
                        </small>
                    </div>

                    <div class="d-grid">
                        <button type="submit" class="btn btn-primary">Iniciar Sesión</button>
                    </div>
                </form>

                <div class="text-center mt-3">
                    <p>
                        <router-link to="/forgot-password" class="text-decoration-none">
                            ¿Olvidaste tu contraseña?
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    name: "LoginView",
    data() {
        return {
            email: "",
            password: "",
            showPassword: false,
            errors: {}, // Almacena los errores de validación
            isMobile: false, // Detecta si es móvil o escritorio
            formSubmitted: false, // Controla si se ha enviado el formulario
        };
    },
    mounted() {
        this.checkIsMobile();
        window.addEventListener('resize', this.checkIsMobile);
    },
    methods: {
        async handleLogin() {
            this.formSubmitted = true; // Marca el formulario como enviado
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/login`, {
                    email: this.email,
                    password: this.password,
                });

                // Guarda el token en localStorage
                localStorage.setItem("token", response.data.token);

                // Redirige al dashboard después del login exitoso
                this.$router.push("/dashboard");

            } catch (error) {
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else if (error.response && error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Credenciales incorrectas',
                        text: 'Por favor, verifica tu correo electrónico y contraseña.',
                    });
                } else {
                    console.error("Error al iniciar sesión:", error.response ? error.response.data : error.message);
                }
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
        checkIsMobile() {
            this.isMobile = window.innerWidth <= 768; // Define el ancho para detectar dispositivos móviles
        },
        clearError(field) {
            // Borra el error cuando el usuario está escribiendo
            if (this.errors[field]) {
                delete this.errors[field];
            }
        }
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkIsMobile);
    }
};
</script>

<style scoped>
/* Estilo del video de fondo */
#bg-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Estilo de la imagen de fondo para móviles */
.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/images/back_login.png');
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.login-page {
    position: relative;
}

.container-fluid {
    background-color: rgba(255, 255, 255, 0);
    border-radius: 10px;
}

.card {
    border-radius: 15px;
}

img {
    max-width: 100%;
    height: auto;
}

.form-control:focus {
    box-shadow: none;
    border-color: #0d6efd;
}

.btn-primary {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.btn-primary:hover {
    background-color: #0b5ed7;
}

.text-danger {
    font-size: 0.875em;
}
</style>
