<template>
    <!-- Navbar -->
    <Navbar />

    <div class="users">

        <!-- Contenido principal -->
        <div class="main-content">
            <!-- Título -->
            <div class="section-header">
                <h2 class="section-title">Gestión de Usuarios</h2>
            </div>

            <!-- Tabla de Usuarios -->
            <div class="grid-item card">
                <div class="card-body">
                    <div class="card-header">
                        <h5 class="card-title">Lista de Usuarios</h5>
                        <button class="btn btn-primary add-button" @click="openUserModal">Agregar Nuevo</button>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Cédula</th>
                                    <th>Nombre Completo</th>
                                    <th>Correo electronico</th>
                                    <th>Rol</th>
                                    <th>Estado</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.id">
                                    <td>{{ user.id_card }}</td>
                                    <td>{{ user.full_name }}</td>
                                    <td>{{ user.email }}</td>
                                    <td>{{ translateRole(user.role) }}</td> <!-- Traducción del rol -->
                                    <td>{{ translateStatus(user.status) }}</td> <!-- Traducción del estado -->
                                    <td>
                                        <button class="btn btn-warning action-button" @click="editUser(user)">
                                            <i class="bi bi-pencil"></i>
                                        </button>
                                        <button class="btn btn-danger action-button" @click="deleteUser(user.id)">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal para crear/editar usuario -->
        <div v-if="isUserModalOpen" class="modal">
            <div class="modal-content">
                <span class="close" @click="closeUserModal">&times;</span>
                <h2>{{ editingUser ? 'Editar Usuario' : 'Crear Usuario' }}</h2>
                <!-- Cambia el título según la acción -->
                <form @submit.prevent="submitUserForm" class="form-grid">
                    <div class="form-group">
                        <label for="cedula">Cédula</label>
                        <input type="text" id="cedula" v-model="newUser.id_card">
                        <small v-if="errors.id_card">{{ errors.id_card[0] }}</small>
                    </div>
                    <div class="form-group">
                        <label for="nombre_completo">Nombre Completo</label>
                        <input type="text" id="nombre_completo" v-model="newUser.full_name">
                        <small v-if="errors.full_name">{{ errors.full_name[0] }}</small>
                    </div>
                    <div class="form-group">
                        <label for="email">Correo Electrónico</label>
                        <input type="email" id="email" v-model="newUser.email">
                        <small v-if="errors.email">{{ errors.email[0] }}</small>
                    </div>

                    <!-- Mostrar el switch solo si estamos editando un usuario existente -->
                    <div v-if="editingUser" class="form-group">
                        <label for="enablePassword">Actualizar contraseña</label>
                        <label class="switch">
                            <input type="checkbox" v-model="enablePassword">
                            <span class="slider round"></span>
                        </label>
                    </div>

                    <!-- Campo de contraseña aparece solo si enablePassword está activado y estamos editando -->
                    <div v-if="enablePassword && editingUser" class="form-group">
                        <label for="password">Contraseña</label>
                        <input type="password" id="password" v-model="newUser.password">
                        <small v-if="errors.password">{{ errors.password[0] }}</small>
                    </div>

                    <div class="form-group">
                        <label for="rol">Rol</label>
                        <select id="rol" v-model="newUser.rol">
                            <option disabled value="">{{ editingUser ? 'Seleccionar opción' : 'Seleccionar opción' }}
                            </option>
                            <option v-for="role in roles" :key="role.id" :value="role.name">{{ translateRole(role.name)
                                }}</option>
                        </select>
                        <small v-if="errors.rol">{{ errors.rol[0] }}</small>
                        <!-- Mostrar el rol actual si estamos editando -->
                        <p v-if="editingUser">Rol actual: {{ translateRole(newUser.rol) }}</p>
                    </div>
                    <div class="form-group">
                        <label for="estado">Estado</label>
                        <select id="estado" v-model="newUser.estado">
                            <option disabled value="">{{ editingUser ? 'Seleccionar opción' : 'Seleccionar opción' }}
                            </option>
                            <option value="Active">Activo</option>
                            <option value="Inactive">Inactivo</option>
                        </select>
                        <small v-if="errors.estado">{{ errors.estado[0] }}</small>
                        <!-- Mostrar el estado actual si estamos editando -->
                        <p v-if="editingUser">Estado actual: {{ translateStatus(newUser.estado) }}</p>
                    </div>

                    <button type="submit" class="btn btn-success">{{ editingUser ? 'Guardar Cambios' : 'Crear'
                        }}</button>
                </form>
            </div>
        </div>
    </div>

    <!-- Footer -->
    <Footer />
</template>

<script>
import Navbar from '../components/Navbar.vue';
import Footer from '../components/Footer.vue';
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'UsersView',
    components: {
        Navbar,
        Footer
    },
    data() {
        return {
            isUserModalOpen: false,
            editingUser: false, // Indica si estamos editando un usuario existente
            enablePassword: false, // Activar/desactivar el campo de contraseña
            newUser: {
                id: null,
                id_card: '',
                full_name: '',
                email: '',
                password: '',
                rol: '',
                estado: 'Activo',
            },
            users: [], // Lista de usuarios
            roles: [], // Lista de roles
            errors: {}, // Para almacenar los errores del backend
        };
    },
    mounted() {
        this.getUsers(); // Cargar usuarios cuando el componente se monta
        this.getRoles(); // Cargar roles cuando el componente se monta
    },
    methods: {
        // Función para traducir el estado
        translateStatus(status) {
            return status === 'Active' ? 'Activo' : 'Inactivo';
        },

        // Función para traducir los roles
        translateRole(role) {
            if (role === 'Admin') return 'Administrador';
            if (role === 'Technical') return 'Técnico';
            return role;
        },

        // Obtener todos los usuarios
        getUsers() {
            axios.get(`${process.env.VUE_APP_API_URL}/users`)
                .then(response => {
                    this.users = response.data;
                })
                .catch(error => {
                    console.error("Error al obtener los usuarios:", error);
                });
        },

        // Obtener todos los roles
        getRoles() {
            axios.get(`${process.env.VUE_APP_API_URL}/roles`)
                .then(response => {
                    this.roles = response.data;
                })
                .catch(error => {
                    console.error("Error al obtener los roles:", error);
                });
        },

        // Crear un nuevo usuario o editar uno existente
        submitUserForm() {
            if (this.editingUser) {
                // Actualizar usuario existente
                const payload = { ...this.newUser };
                if (!this.enablePassword) {
                    delete payload.password; // No enviar la contraseña si no se va a actualizar
                }

                axios.put(`${process.env.VUE_APP_API_URL}/users/${this.newUser.id}`, payload)
                    .then(() => {
                        this.getUsers(); // Refresca la lista de usuarios
                        this.closeUserModal();
                        Swal.fire({
                            icon: 'success',
                            title: 'Usuario actualizado',
                            text: 'El usuario ha sido actualizado exitosamente',
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.data.errors) {
                            this.errors = error.response.data.errors;
                        }
                        console.error("Error al actualizar el usuario:", error);
                    });
            } else {
                // Crear un nuevo usuario
                axios.post(`${process.env.VUE_APP_API_URL}/users`, this.newUser)
                    .then(() => {
                        this.getUsers(); // Refresca la lista de usuarios
                        this.closeUserModal();
                        Swal.fire({
                            icon: 'success',
                            title: 'Usuario creado',
                            text: 'El usuario ha sido creado exitosamente',
                        });
                    })
                    .catch(error => {
                        if (error.response && error.response.data.errors) {
                            this.errors = error.response.data.errors;
                        }
                        console.error("Error al crear el usuario:", error);
                    });
            }
        },

        // Abrir modal para crear un nuevo usuario
        openUserModal() {
            this.editingUser = false;
            this.resetUserForm();
            this.enablePassword = true; // Permitir ingresar la contraseña al crear un usuario
            this.isUserModalOpen = true;
        },

        // Abrir modal para editar un usuario existente
        editUser(user) {
            this.editingUser = true;
            this.newUser = { ...user }; // Copiar los datos del usuario seleccionado al formulario
            this.newUser.password = ''; // No mostrar ni editar la contraseña a menos que se habilite
            this.enablePassword = false; // Deshabilitar el campo de contraseña por defecto
            this.isUserModalOpen = true;
        },

        // Cerrar el modal y restablecer los datos
        closeUserModal() {
            this.isUserModalOpen = false;
            this.errors = {}; // Limpiar los errores al cerrar el modal
        },

        // Restablecer los datos del formulario de creación/edición
        resetUserForm() {
            this.newUser = {
                id: null,
                id_card: '',
                full_name: '',
                email: '',
                password: '',
                rol: '',
                estado: 'Activo',
            };
        },

        // Eliminar usuario con confirmación de SweetAlert
        deleteUser(userId) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: "¡No podrás revertir esta acción!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete(`${process.env.VUE_APP_API_URL}/users/${userId}`)
                        .then(() => {
                            this.getUsers(); // Refresca la lista de usuarios
                            Swal.fire(
                                '¡Eliminado!',
                                'El usuario ha sido eliminado.',
                                'success'
                            );
                        })
                        .catch(error => {
                            console.error("Error al eliminar el usuario:", error);
                            Swal.fire(
                                'Error',
                                'No se pudo eliminar el usuario.',
                                'error'
                            );
                        });
                }
            });
        }
    }
};
</script>

<style scoped>
html,
body {
    margin: 0;
    padding: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.users {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.main-content {
    flex: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #f8f9fa;
    /* Fondo claro */
    overflow-y: auto;
    /* Permite desplazamiento vertical */
}

.section-header {
    text-align: left;
    padding: 20px;
    color: #343a40;
    /* Texto oscuro */
    background-color: #e9ecef;
    /* Fondo más claro */
    border-bottom: 1px solid #dee2e6;
    /* Borde claro */
}

.section-title {
    font-size: 2em;
    font-weight: 600;
    color: #000;
    /* Título en negro */
}

.users-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;
}

.card {
    background-color: #ffffff;
    /* Fondo blanco */
    color: #212529;
    /* Texto oscuro */
    border: none;
    /* Sin bordes */
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* Transiciones suaves */
}

.card:hover {
    transform: none;
    /* No aplicar ninguna transformación */
    box-shadow: none;
    /* No cambiar la sombra */
}

.card-body {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.card-title {
    font-size: 1.5em;
    font-weight: bold;
    color: #000;
    /* Título en negro */
}

.table-responsive {
    width: 100%;
    overflow-x: scroll;
}

.table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    /* Fondo de tabla claro */
    color: #212529;
    /* Texto oscuro */
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    /* Borde claro para las celdas */
}

.table th {
    background-color: #e9ecef;
    /* Fondo claro para las cabeceras */
    font-weight: bold;
}

.table tr:hover {
    background-color: #f8f9fa;
    /* Resalta la fila al pasar el mouse */
}

.action-button {
    margin-right: 5px;
    padding: 4px 8px;
    font-size: 0.8em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-info {
    background-color: #17a2b8;
    color: #fff;
}

.btn-warning {
    background-color: #ffc107;
    color: #fff;
}

.btn-danger {
    background-color: #dc3545;
    color: #fff;
}

.btn-info:hover,
.btn-warning:hover,
.btn-danger:hover {
    opacity: 0.8;
}

.add-button {
    background-color: #0d6efd;
    border: none;
    padding: 8px 16px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.add-button:hover {
    background-color: #0b5ed7;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    /* Ajusta el ancho en pantallas pequeñas */
    max-width: 1200px;
    /* Ajusta el ancho máximo en pantallas grandes */
    transform: translateY(0%);
    /* Centra el modal verticalmente */
}

/* Aumentar espaciado entre inputs */
.form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    /* Aumenta el espacio entre los inputs */
}

/* Mejora la responsividad en pantallas más grandes */
@media (min-width: 992px) {
    .modal-content {
        width: 80%;
        /* Ancho mayor para pantallas grandes */
        max-width: 1400px;
        /* Ajusta el ancho máximo */
    }

    .form-grid {
        gap: 30px;
        /* Aumenta más el espacio entre los inputs en pantallas grandes */
    }
}

/* Para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
    .form-grid {
        grid-template-columns: 1fr;
        /* Pone los inputs en una sola columna en móviles */
    }
}

.close {
    position: absolute;
    /* Posiciona el botón de cerrar dentro del modal */
    top: 10px;
    /* Ajusta la distancia desde la parte superior */
    right: 20px;
    /* Alinea el botón a la derecha */
    font-size: 32px;
    /* Tamaño más grande para la X */
    cursor: pointer;
    /* Cambia el cursor al pasar el mouse */
    color: #000;
    /* Color de la X */
    font-weight: bold;
    /* Hace la X más gruesa */
}

.close:hover {
    color: #ff0000;
    /* Cambia el color al rojo cuando se pasa el mouse por encima */
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

button[type="submit"] {
    background-color: #28a745;
    border: none;
    padding: 10px 20px;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #218838;
}

/* Switch más pequeño para habilitar el campo de contraseña */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    /* Hacemos el switch más pequeño */
    height: 20px;
    /* Ajustamos el alto del switch */
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: #28a745;
}

input:checked+.slider:before {
    transform: translateX(18px);
    /* Ajustamos el desplazamiento del switch más pequeño */
}

.slider.round {
    border-radius: 20px;
}

.slider.round:before {
    border-radius: 50%;
}
</style>
