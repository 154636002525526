import { createRouter, createWebHistory } from 'vue-router';
import Login from './views/Login.vue';
import Dashboard from './views/Dashboard.vue';
import Activities from './views/Activities.vue';
import Users from './views/Users.vue';
import Record from './views/Record.vue';
import Settings from './views/Settings.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    // Evitar acceso al login si el usuario ya está logueado
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('token')) {
        next('/dashboard'); // Redirigir al dashboard si está autenticado
      } else {
        next(); // Permitir el acceso si no está autenticado
      }
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next('/'); // Redirigir al login si no está autenticado
      } else {
        next(); // Permitir acceso si está autenticado
      }
    },
  },
  {
    path: '/activities',
    name: 'Activities',
    component: Activities,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next('/'); // Redirigir al login si no está autenticado
      } else {
        next(); // Permitir acceso si está autenticado
      }
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next('/'); // Redirigir al login si no está autenticado
      } else {
        next(); // Permitir acceso si está autenticado
      }
    },
  },
  {
    path: '/record',
    name: 'Record',
    component: Record,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next('/'); // Redirigir al login si no está autenticado
      } else {
        next(); // Permitir acceso si está autenticado
      }
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem('token')) {
        next('/'); // Redirigir al login si no está autenticado
      } else {
        next(); // Permitir acceso si está autenticado
      }
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/', // Redirigir a la página de login si la ruta no existe
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Verificación global para proteger las rutas si es necesario en el futuro
router.beforeEach((to, from, next) => {
  const publicPages = ['/']; // Definir rutas públicas que no requieren autenticación
  const authRequired = !publicPages.includes(to.path); // Verifica si la ruta requiere autenticación
  const loggedIn = localStorage.getItem('token'); // Verifica si el usuario está autenticado

  if (authRequired && !loggedIn) {
    return next('/'); // Redirigir al login si intenta acceder a una ruta protegida
  }

  next(); // Continuar con la navegación
});

export default router;
